import { v4 as uuidv4 } from 'uuid';

import { API_URLS, callCoreApi, METHODS } from 'api';
import { COOKIE_KEY, getCookie } from 'utils/cookies';

interface ITrackingApiParams {
  external_id: string;
  type: 'pageview';
  url: string;
  google_cid: string;
  gclid: string;
  fbclid: string;
  fbp: string;
  fbc: string;
  appsflyer_web_id: string;
  platform: 'web';
  coupon?: string;
  tune_offer_id: string;
  tune_transaction_id: string;
  impact_click_id: string;
  source: string;
  medium: string;
  campaign: string;
}

export const handlePageView = (url: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  const fbclid = queryParams.get('fbclid');
  const gclid = queryParams.get('gclid');
  const coupon = queryParams.get('coupon');
  const uuid = uuidv4();
  const fbclidValue = getCookie(COOKIE_KEY.FBCLID) || fbclid || '';
  const gclidValue = getCookie(COOKIE_KEY.GCLID) || gclid || '';
  const couponValue = getCookie(COOKIE_KEY.COUPON) || coupon || '';
  const fbp = getCookie(COOKIE_KEY._FBP);
  const fbc = getCookie(COOKIE_KEY._FBC);
  const appsflyer_web_id = getCookie(COOKIE_KEY.AF_USER_ID);
  const tune_offer_id = getCookie(COOKIE_KEY.TUNE_OFFER_ID) || '';
  const tune_transaction_id = getCookie(COOKIE_KEY.TUNE_TRANSACTION_ID) || '';
  const impact_click_id = getCookie(COOKIE_KEY.IMPACT_CLICK_ID) || '';
  const source = getCookie(COOKIE_KEY.UTM_SOURCE) || '';
  const medium = getCookie(COOKIE_KEY.UTM_MEDIUM) || '';
  const campaign = getCookie(COOKIE_KEY.UTM_CAMPAIGN) || '';
  const pageUrl = `https://${window.location.hostname}${url}${window.location.search}`;
  let google_cid;
  let gaTracker;

  if (window.ga?.getAll && typeof window.ga?.getAll === 'function') {
    google_cid = window.ga?.getAll()?.[0]?.get('clientId');
    gaTracker = window.ga?.getAll()?.[0];
  }

  callCoreApi<ITrackingApiParams>({
    method: METHODS.POST,
    url: API_URLS.TRACKING_HITS,
    data: {
      url: pageUrl,
      external_id: uuid,
      type: 'pageview',
      google_cid,
      appsflyer_web_id,
      gclid: gclidValue,
      fbclid: fbclidValue,
      fbc,
      fbp,
      platform: 'web',
      coupon: couponValue,
      tune_offer_id,
      tune_transaction_id,
      impact_click_id,
      source,
      medium,
      campaign,
    },
  });

  if (gaTracker) {
    gaTracker.send('pageview', `${url}${window.location.search}`, {
      cd1: uuid,
      cd2: appsflyer_web_id,
      cd3: gclidValue,
      cd4: fbclidValue,
      cd5: couponValue,
    });
  }
};
