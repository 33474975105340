import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import styled from '@emotion/styled';

import * as animationData from 'assets/lottieAnimations/breathing_timer.json';
import Text from 'components/Text';
import Link from 'components/Link';
import Header from 'components/Header';
import breathing from 'assets/pngs/breathing.jpg';
import PublicComponent from 'navigation/PublicComponent';
import FullScreenWrapper from 'components/FullScreenWrapper';
import { BREAKPOINTS, ROUTES } from 'utils/constants';
import { handlePageView } from 'features/Tracking/trackingApiRequests';

interface IAnimatedText {
  opacity: number;
}

const Container = styled.div({
  margin: '60px 0',
  textAlign: 'center',
  [BREAKPOINTS[500]]: {
    margin: '70px 0',
  },
  svg: {
    maxWidth: 300,
    [BREAKPOINTS[500]]: {
      maxWidth: 230,
    },
  },
});

const Percentage = styled(Text)({
  fontWeight: 600,
  marginTop: 18,
  marginBottom: 16,
});

const Title = styled(Text)({
  fontWeight: 600,
  marginBottom: 32,
  maxWidth: 300,
});

const AnimatedText = styled(Text)<IAnimatedText>((props) => ({
  color: 'rgba(255, 255, 255, 0.9)',
  opacity: props.opacity,
  transition: 'opacity 0.5s ease-in-out',
}));

const Note = styled(Text)({
  color: 'rgba(255, 255, 255, 0.9)',
  lineHeight: '22px',
  maxWidth: 300,
  marginBottom: 12,
});

const BoldNote = styled.span((props) => ({
  fontWeight: 700,
  color: props.theme.colors.white,
}));

const StyledLink = styled(Link)({
  marginTop: 24,
  width: '100%',
  maxWidth: 230,
});

const Personalizing: React.FC = () => {
  const intervalRef = useRef<NodeJS.Timeout>();
  const [percentage, setPercentage] = useState<number>(0);
  const [animatedTextOpacity, setAnimatedTextOpacity] = useState<number>(1);
  const [animatedText, setAnimatedText] = useState<string>('Analyzing your answers');
  const [isCompleted, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    handlePageView('/personalizing');

    intervalRef.current = setInterval(() => {
      setPercentage((value) => value + 1);
    }, 100);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (percentage === 30 && animatedTextOpacity === 1) {
      setAnimatedTextOpacity(0);
    }

    if (percentage === 40 && animatedTextOpacity === 0) {
      setAnimatedText('Preparing app content');
      setAnimatedTextOpacity(1);
    }

    if (percentage === 67 && animatedTextOpacity === 1) {
      setAnimatedTextOpacity(0);
    }

    if (percentage === 74 && animatedTextOpacity === 0) {
      setAnimatedText('Generating recommendations');
      setAnimatedTextOpacity(1);
    }

    if (intervalRef.current && percentage >= 100) {
      setAnimatedText('Completed');
      clearInterval(intervalRef.current);
      setCompleted(true);
    }
  }, [percentage]);

  return (
    <PublicComponent>
      <FullScreenWrapper backgroundImage={breathing}>
        <Container>
          <Header isVisible logoLink={ROUTES.PERSONALIZING} />
          <Title type="h1" align="center">
            {!isCompleted ? 'Personalizing app content...' : 'Your personalized app is ready.'}
          </Title>
          <Note align="center">
            {!isCompleted ? "It won't take long" : 'Press '}
            <BoldNote>{!isCompleted ? '' : 'continue'}</BoldNote>
          </Note>
          <Lottie options={{ autoplay: true, animationData }} />
          <Percentage type="h1" align="center">
            {percentage}%
          </Percentage>
          <AnimatedText opacity={animatedTextOpacity} align="center">
            {animatedText}
          </AnimatedText>
          {isCompleted && <StyledLink to={ROUTES.REGISTER}>Continue</StyledLink>}
        </Container>
      </FullScreenWrapper>
    </PublicComponent>
  );
};

export default Personalizing;
